<template>
    <div class="scroll-div flex" style="padding-top: 0;" ref="panel">
        <ts-table ref="table" class="flex-item" :cols="cols" :data="list" :loading="loading" zero-to-empty
            :error-code="error" hide-page full hide-detail :style="{ height: realHeight + 'px' }">
            <template #searchLeft>
                <ts-search-left v-if="isSearchLeft" :searchCols="searchCols" :table-name="tableName" :cols="cols"
                    @searchClose="searchClose" @handleFilter="handleFilter"></ts-search-left>
            </template>
            <template #left>
                <ts-table-operate :table-name="tableName" style="margin: 0 20px;align-items: center;"
                    @searchShowLeft="searchShowLeft" :cols="cols" @handleFilter="handleFilter" show-export
                    @leadingOut="exportInfo()">
                    <div>筛选：
                        <ts-year-select v-model="year" @change="getData"></ts-year-select>
                    </div>
                </ts-table-operate>
            </template>
        </ts-table>
    </div>
</template>

<script>
import myMixin from '@/util/mixin'
export default {
    props: ['compCode', 'visitId'],
    mixins: [myMixin],
    data() {
        return {
            year: this.$variable.lastYear.key,
            tableName: 'aim_base_drugs_winning',

            searchCols: [],
            loading: false,
            list: [],
            error: '',
            cols: this.$help.generalCols([
                this.$variable.indexColumn.drug({
                    listQueryModel: 1
                }),
                {
                    fieldName: 'unifySpec',
                    name: '规格',
                    width: 100,
                    listQueryView: '是',
                    listOrderView: true,
                    listQueryModel: 1
                },
                {
                    fieldName: 'highPrice',
                    name: `高价（元）`,
                    width: 120,
                    listOrderView: true,
                    listClass: 'tc'
                },
                {
                    fieldName: 'highProvince',
                    name: '高价省',
                    width: 120,
                    listOrderView: true,
                    listClass: 'tc'
                },
                {
                    fieldName: 'lowPrice',
                    name: '低价（元）',
                    width: 120,
                    listOrderView: true,
                    listClass: 'tc'
                },
                {
                    fieldName: 'lowProvince',
                    name: '低价省',
                    listOrderView: true,
                    width: 120,
                    listClass: 'tc'
                },
            ]),
            realHeight: 100,
            stop: true,
        }
    },
    created() {
        this.getData()
    },
    mounted() {
        this.realHeight = this.$refs.panel.clientHeight - 50
    },
    methods: {
        handleFilter(cols) {
            this.searchCols = cols
            this.getData()
        },
        getData() {
            this.loading = true
            const obj = {
                compCode: this.compCode,
            }

            const conditionList = []
            this.searchCols.forEach(item => {
                if (item.model) {
                    switch (item.field.listQueryModel) {
                        case this.$variable.searchType.LIKE:
                            conditionList.push({
                                name: item.field.fieldName,
                                op: 'like',
                                value: item.model,
                            })
                            break;
                        case this.$variable.searchType.SELECT:
                            if (item.model && item.model.length > 0) {
                                conditionList.push({
                                    name: item.field.fieldName,
                                    op: 'in',
                                    value: item.model,
                                })
                            }
                            break;
                        default:
                            if (item.model && item.model.length === 2) {
                                conditionList.push({
                                    name: item.field.fieldName,
                                    op: 'ge',
                                    value: item.model[0],
                                })
                                conditionList.push({
                                    name: item.field.fieldName,
                                    op: 'le',
                                    value: item.model[1]
                                })
                            }
                    }
                }
            });
            // 旧 company/mainfDrugWinningInformations/drugWinningInformation
            this.$api.post('company/drugWinningInformations/drugWinningInformation', {
                params: obj,
                conditionList,
                model: this.$variable.yearMap[this.year]
            }, { 'Visit-Id': this.$help.getVisitId(this.visitId) })
                .then(res => {
                    this.error = res.code
                    if (res.code === this.$variable.successCode) {
                        this.list = res.data.map(item => {
                            item.lowPrice = item.lowPrice ? parseFloat(item.lowPrice) : ''
                            item.highPrice = item.highPrice ? parseFloat(item.highPrice) : ''
                            return item
                        })
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },
        exportInfo(txt = '确定导出吗', isForce) {
            this.$store.commit('confirm', {
                content: txt,
                callback: (next, back) => {
                    const obj = {
                        compCode: this.compCode,
                    }

                    const conditionList = []
                    this.searchCols.forEach(item => {
                        if (item.model) {
                            switch (item.field.listQueryModel) {
                                case this.$variable.searchType.LIKE:
                                    conditionList.push({
                                        name: item.field.fieldName,
                                        op: 'like',
                                        value: item.model,
                                    })
                                    break;
                                case this.$variable.searchType.SELECT:
                                    if (item.model && item.model.length != 0) {
                                        conditionList.push({
                                            name: item.field.fieldName,
                                            op: 'in',
                                            value: item.model,
                                        })
                                    }
                                    break;
                                default:
                                    if (item.model && item.model.length === 2) {
                                        conditionList.push({
                                            name: item.field.fieldName,
                                            op: 'ge',
                                            value: item.model[0],
                                        })
                                        conditionList.push({
                                            name: item.field.fieldName,
                                            op: 'le',
                                            value: item.model[1]
                                        })
                                    }
                            }
                        }
                    });
                    this.$api.post('company/drugWinningInformations/exportDrugWinningInformation',
                        {
                            params: obj,
                            conditionList,
                            model: {
                                yearKey: this.$variable.yearMap[this.year],
                                force: isForce
                            }
                        }
                    )
                        .then(res => {
                            if (!(res.success || res.code === 'N0001')) {
                                next()
                                this.$store.commit('error', res.message)
                            } else if (res.code === 'N0001') {
                                next()
                                this.exportInfo(res.message, true)
                            } else {
                                this.$help.getExportStatus(res.data.id, data => {
                                    if (data === true) {
                                        next()
                                        this.$store.commit('success', '导出成功')
                                    } else {
                                        back()
                                        this.$store.commit('error', data)
                                    }
                                })
                            }
                        })
                        .catch(back)
                },
            })
        },
    }
}
</script>